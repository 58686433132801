import {  Container, Row, Col, Navbar, Nav } from "../../utilis/Bootstrap.js";

export default function Header() {
  return (
    <header>
 
      <Container className="mb-4">

        <Row>
          <Col md={10}>
        about me hear
          </Col>
        </Row>
      </Container>
    </header>
  );
}
